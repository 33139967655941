import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"

class PrecisionSystems extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-precisionsystems inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                </h2>
            </div>

            <article className="article project inuit-u-p0">

                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="http://precision.ma" title="" target="_blank">
                                <img src="/img/clients/precision-icons-app.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">PRECISION SYSTEMS.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://precision.ma/" title="See Dolfn!" target="_blank">precision.ma</a>
                            </span>
                            <span className="project__detail">Year 2018</span>
                        </p>

                        <ul className="article__share-list">
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--facebook" target="blank" title="Share on Facebook"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--twitter" target="blank" title="Share on Twitter"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--linkedin" target="blank" title="Share on Linkedin"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                                    className="article__share-link article__share-link--gplus" target="blank" title="Share on Google Plus"></a>
                            </li>
                        </ul>
                    </aside>

                    <h1 className="article__heading">LA CAISSE ENREGISTREUSE SUR IPAD.</h1>
                    <p className="article__para">
                        Gérez vos points de vente sur le bout des doigts<br />
                        Centralisez sur un seul système les commandes en ligne, la commande et l'encaissement sur place ainsi que
                        la gestion de stock de vos magasins et centrales d'achat.<br />
                        Tous les processus sont connectés, avec des interfaces personnalisées et des privilèges adaptées à chaque
                        poste.
                    </p>
                    <p className="article__para">
                        <span className="project__detail project__detail--small">Digital transformation, technologie, creative design
                            et data science</span>
                    </p>
                </div>
                <hr style={{backgroundColor: "white", height: "1px",  borderTop: "1px solid white"}} />
                <div className="article__body">
                    <h2 className="article__heading" style={{textAlign: "center"}}>L'ÉCOSYSTÈME PRECISION SYSTEMS</h2>
                </div>


                <p className="article__belt">
                    <img className="article__belt-image" src="/img/projects/precisionsystems/ps-ipad-banner.jpg" alt="mobile-apps" />
                </p>

                <div className="article__body">

                    <h2 className="article__heading">La caisse enregistreuse sur Ipad</h2>
                    <p className="article__para">
                        La caisse Precision systems sur ipad permet la prise de commande Comptoir et offre une grande flexibilité
                        pour ajouter modifier ou supprimé un article ou une option sur un article a encaissée. L'Impression de
                        ticket de caisse est instantanée et paramétrable notamment le Contrôle du tiroir de caisse.<br />
                    </p>
                    <p className="article__para">
                        La gestion de stock devient un jeu d'enfant grâce à l'intelligence du system, qui permet le Calcul du stock
                        théorique à l'instant et la gestion même des Péremptions & des pertes. Étant connecté à l'ensemble de
                        l'ecosystem Precision Systems, le caissier peut emmètre des demandes de réapprovisionnement, visualiser les
                        livraisons programmées du jour et confirmer la réception de la marchandise.<br />
                    </p>
                    <p className="article__para">
                        Quant à la recette, la caisse gère avec précision le Fond de caisse, les billets en caisses, les paiements
                        espèce, par chèques et les transactions TPE. En parallèle les dépenses, prélèvements caisse et autres sont
                        contrôlé, historisés, commenté et comptabilisé.
                    </p>

                </div>

                <div className="article__body">

                    <h2 className="article__heading">Interface : Réseaux de Magasins</h2>

                    <p className="article__belt">
                        <img className="article__belt-image" src="/img/projects/precisionsystems/banner-stores-network.jpg"
                            alt="mobile-apps" />
                    </p>

                    <p className="article__para">
                        Pour une entreprise installée sur différents points de vente, il est capital de savoir comment se porte son
                        réseau de magasins. Plusieurs aspects de la vente sont à prendre en compte.<br />

                        Precision Systems permet de mesurer en temps réel la performance en vous donnant assez de visibilité sur
                        tous les points de vente du réseau de magasins. <br />
                    </p>
                    <p className="article__para">

                        Qu’il soit question de l’analyse des résultats du réseau de magasins ou de celui d’un point de vente
                        compris dans ce réseau, l'interface Réseaux de Magasins vous permet de voir par periode les indicateurs
                        suivants :<br />

                        - Achalandage : le trafic à l’intérieur du magasin<br />
                        - Panier moyen<br />
                        - Chiffre d'affaire (Espece, cheque ou TPE)<br />
                        - Contribution de chaque magasin au chiffre d'affaire global<br />
                        - Les Ecarts de stock (Dépot, Transport, Point de vente, ...)<br />
                        - Les Ecarts de caisse<br />
                        - Les dépenses<br />
                        - Les prelevements<br />
                        - Contribution de chaque produit au chiffre d'affaire global<br />
                        - Un rapprochement entre le stock théorique et stock réel<br />
                        - La Consommation en matière première<br />
                        - Les Crèdit Clients<br />
                        - Suivie des comptes des clients fidèles<br />

                    </p>
                    <p className="article__para">
                        L'interface permet aux gestionnaires :
                        - Renseigner les montant especes recuperés de chez les points de ventes
                        - Renseigner les versements effectués par les points de ventes
                        - Renseigner les récapitulatifs de fin de journée des terminaux de paiements electroniques
                        - Valider les demnandes de réaprovisionnement<br />
                        - Valider les retours<br />
                        - Déclencher des livraisons a un ou plusiers points de ventes<br />
                    </p>
                    <p className="article__para">

                        L'interface permet aussi aux controleurs de gestion d'apporter des correctives aux quantitées envoyés,
                        reçues, retournés, périmés, ... Tout en gardant une trace de chaque modification avec un maximum de
                        détails.

                    </p>
                    <p className="article__para">

                        L'objective est l’uniformisation du réseau de magasins en automatisant les actions suivantes :<br />
                        - Assurer la qualité des services<br />
                        - Maintenir le contrôle<br />
                        - Mieux gérer les stocks<br />
                        - Améliorer les collaborations
                    </p>
                </div>


                <div className="article__body">

                    <h2 className="article__heading">Applications mobiles ANDROID & IOS</h2>

                </div>

                <p className="article__belt">
                    <img className="article__belt-image" src="/img/projects/precisionsystems/banner-mobile-app.jpg" alt="mobile-apps" />
                </p>

                <div className="article__body">

                    <p className="article__para">
                        L'extension de l'interface Réseaux de Magasins sur application mobile vous donne proximité et réactivité en
                        mettant à votre disposition tous le contenu de cette interface ajouté à des notifications personnalisés
                        pour un ou plusieurs magasins a chaque :<br />
                        - Ouveture de caisse<br />
                        - Clôture de caisse<br />
                        - Prélèvement depuis une caisse<br />
                        - Stock critique de l'un des produits dans le stock magasin
                    </p>
                </div>

                <div className="article__body">

                    <h2 className="article__heading">Interface : Relation Fournisseur</h2>

                </div>

                <p className="article__belt">
                    <img className="article__belt-image" src="/img/projects/precisionsystems/banner-relation-fournisseur.jpg"
                        alt="mobile-apps" />
                </p>

                <div className="article__body">

                    <p className="article__para">
                        - Gestion des Fournisseurs <br />
                        - Gestion et contrôle des Commandes et Factures par fournisseur <br />
                        - Emission de bon de commande fournisseur, contrôle des achats, factures <br />
                        - Suivie des commandes depuis la création de BC jusqu'au paiement en passant par la livraison <br />
                        - Maitrise des enregistrements des paiements fournisseurs (Upload des accusés cheque, espece, virement,
                        ...) <br />
                        - Inventaire et réapprovisionnement de marchandises <br />
                        - Gestion des produits par : Famille - Sous Famille <br />
                        - Gestion des unités d’achats (Produits en Pack ou en Vrac) <br />

                    </p>
                    <p className="article__para">

                        l'interface Relation Fournisseur intègre plusieurs notions sur les achats, dont le bon de commande
                        fournisseur, le bon de réception de commande ou de marchandises fournisseurs ainsi que le règlement des
                        dépenses et les mouvements de stock.
                    </p>
                </div>

                <hr />

            </article>
            <WhoAreWe />
        </Layout>
    
  )

}

export default PrecisionSystems
